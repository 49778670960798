/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import vectorBack from "../../assets/images/back-btn-vector.svg";
import brownRightArrow from "../../assets/images/brownArrowRight.svg";
import leftArrow from "../../assets/images/cardBackBtn.svg";
import rightArrow from "../../assets/images/cardNextBtn.svg";
import Header from "../../components/Header";
import config from "../../config.json";
import { CardContext } from "../../context/CardContext";
import { LanguageContext } from "../../context/languageContext";
import { getCollections } from "../../contracts/contractMethdos/contractMethdos";
import Purchase from "./Purchase";

const NewCardPage = () => {
  const { collectionId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const address = searchParams.get("address");
  const { collectionList, setCollectionList } = useContext(CardContext);
  const { t } = useTranslation();
  const langCtx = useContext(LanguageContext);

  const [loading, setLoading] = useState(false);
  const [currentCard, setCurrentCard] = useState(null);
  const [nextCard, setNextCard] = useState(null);
  const [previousCard, setPreviousCard] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  //#region setting prev-,next-,currentcards
  const setCards = (localCollectionList) => {
    const getObjectWithAdjacentItems = (array, targetId) => {
      const currentIndex = array.findIndex((obj) => obj.id === targetId);
      const currentObject = array.find((obj) => obj.id === targetId);
      const previousObject = array[currentIndex - 1] || null;
      const nextObject = array[currentIndex + 1] || null;

      setCurrentCard(currentObject);
      setNextCard(nextObject);
      setPreviousCard(previousObject);
    };
    getObjectWithAdjacentItems(localCollectionList, collectionId);
  };
  //#endregion
  //#region contract calls
  useEffect(() => {
    // setOwner(currentCard?.owned ? currentCard.owned : true);
    // const checkIfAddressMintedFromTheCollection = async () => {
    //   const alreadyMinted = await addressAlreadyMinted(collectionId, address);
    //   setOwner(alreadyMinted);
    // };
    // if (address) {
    //   checkIfAddressMintedFromTheCollection();
    // }

    if (collectionList) {
      setCards(collectionList);
    } else {
      const getDatasFromContract = async () => {
        try {
          setLoading(true);

          const localCollectionList = await getCollections().then(
            (response) => {
              if (response.length > 0) {
                return response;
              }
            }
          );
          setCollectionList(localCollectionList);
          setCards(localCollectionList);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      };
      getDatasFromContract();
    }
  }, [collectionId]);
  //#endregion
  //#region navigation

  const buttons = () => {
    return (
      <div className="nav-outer-container">
        <div className="nav-container">
          {previousCard ? (
            <div className="nav-button" onClick={handlePrevious}>
              <img
                src={brownRightArrow}
                style={{ marginRight: "10px", transform: "scaleX(-1)" }}
              />
              <p
                style={{
                  color: config.MUZEUM.textColor,
                }}
              >
                {" "}
                {t("cardPrevButton")}
              </p>
            </div>
          ) : (
            <div className="nav-button"></div>
          )}
          {nextCard ? (
            <>
              <div className="nav-button" onClick={handleNext}>
                <p
                  style={{
                    color: config.MUZEUM.textColor,
                  }}
                >
                  {t("cardNextButton")}
                </p>
                <img src={brownRightArrow} style={{ marginLeft: "10px" }} />
              </div>
            </>
          ) : (
            <div className="nav-button"></div>
          )}
        </div>
      </div>
    );
  };
  const handleNext = () => {
    navigate(
      `/experience/${nextCard.id}${address ? "?address=" + address : ""}`
    );
  };
  const handlePrevious = () => {
    navigate(
      `/experience/${previousCard.id}${address ? "?address=" + address : ""}`
    );
  };
  //#endregion
  //#region right text side

  const textSide = () => {
    return (
      <div className="text-side">
        <ul>
          {/* li */}
          <li
            className="card-text-normal"
            style={{
              fontSize: "14px",
              textTransform: "uppercase",
              color: config.MUZEUM.greyColor,
            }}
          >
            {t("mintedCardsText")}:
          </li>
          <li className="card-text-normal">
            <span
              className="text-value"
              style={{
                color: config.MUZEUM.textColor,
              }}
            >
              {currentCard.mintedSupply}
            </span>
          </li>
          <hr
            style={{
              opacity: "0.3",
            }}
          />
          {/* li */}

          {/* li */}
          <li
            className="card-text-normal"
            style={{
              fontSize: "14px",
              textTransform: "uppercase",
              color: config.MUZEUM.greyColor,
            }}
          >
            {t("typeText")}:{" "}
          </li>
          <li className="card-text-normal">
            <span
              className="text-value"
              style={{
                color: config.MUZEUM.textColor,
              }}
            >
              {langCtx.language === "hu"
                ? currentCard.typeHu
                : langCtx.language === "ro"
                ? currentCard.typeRo
                : currentCard.typeEn}
            </span>
          </li>
          <hr
            style={{
              opacity: "0.3",
            }}
          />
          {/* li */}
          <li
            className="card-text-normal"
            style={{ marginTop: "20px", color: config.MUZEUM.textColor }}
          >
            {langCtx.language === "hu"
              ? currentCard.descriptionHu.split("https", 1)
              : langCtx.language === "ro"
              ? currentCard.descriptionRo.split("https", 1)
              : currentCard.descriptionEn.split("https", 1)}
          </li>
          <hr
            style={{
              opacity: "0.3",
            }}
          />
          {/* li */}
          <Purchase />
        </ul>
      </div>
    );
  };
  //#endregion
  return (
    <>
      <div className="new-card-container">
        <Header />
        <div className="card-container-outer">
          <div className="wallet-container ">
            {!loading ? (
              <>
                {currentCard && (
                  <>
                    {address && (
                      <div
                        className="back-to-cards"
                        onClick={() => navigate(`/?address=${address}`)}
                      >
                        <img src={vectorBack} />
                        <p> {t("backToCards")}</p>
                      </div>
                    )}
                    <div
                      className="new-card-title"
                      style={{
                        color: config.MUZEUM.titleColor,
                        fontFamily: config.MUZEUM.extraBoldFont,
                        textAlign: "start",
                        marginBottom: "20px",
                      }}
                    >
                      <h1
                        style={{
                          color: config.MUZEUM.titleColor,
                          fontFamily: config.MUZEUM.extraBoldFont,
                        }}
                      >
                        {langCtx.language === "hu"
                          ? currentCard.nameHu
                          : langCtx.language === "ro"
                          ? currentCard.nameRo
                          : currentCard.nameEn}
                      </h1>
                    </div>
                    <div className="card-container">
                      <div className="card-side">
                        {previousCard && (
                          <div
                            className="nav-button nav-button-mobile nav-button-mobile-left"
                            onClick={handlePrevious}
                          >
                            <img src={leftArrow} />
                          </div>
                        )}
                        <img
                          onClick={toggleModal}
                          className="current-card-image"
                          src={currentCard.image}
                          style={{ maxWidth: "100%" }}
                        />
                        {nextCard && (
                          <>
                            <div
                              className="nav-button nav-button-mobile nav-button-mobile-right"
                              onClick={handleNext}
                            >
                              <img src={rightArrow} />
                            </div>
                          </>
                        )}
                      </div>

                      {textSide()}
                    </div>
                    {buttons()}
                  </>
                )}
              </>
            ) : (
              <>
                <div className="loader">
                  <ClipLoader
                    color={"#999999"}
                    loading={loading}
                    size={100}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="new-card-modal" onClick={toggleModal}>
          <img src={currentCard?.image} alt="Modal Image" />
        </div>
      )}
    </>
  );
};

export default NewCardPage;
