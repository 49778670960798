import { BrowserRouter, Route, Routes } from "react-router-dom";
import CardContextProvider from "./context/CardContext.js";
import LanguageContextProvider from "./context/languageContext.js";
import routes from "./routes/Routes.js";
function App() {
  return (
    <LanguageContextProvider>
      <CardContextProvider>
        <div className="App">
          <BrowserRouter basename="/">
            <Routes>
              {routes.map(({ path, component, exact }) => (
                <Route
                  key={path}
                  path={path}
                  element={component}
                  exact={exact}
                />
              ))}
            </Routes>
          </BrowserRouter>
        </div>
      </CardContextProvider>
    </LanguageContextProvider>
  );
}

export default App;
