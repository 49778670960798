import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import config from "../../config.json";

import "./Purchase.css";

const Purchase = () => {
  const [digitalEditionCheckbox, setDigitalEditionCheckbox] = useState(false);
  const [payWithCash, setPayWithCash] = useState(false);
  const [openPurchaseModal, setOpenPurchaseModal] = useState(false);
  const [modalText, setModalText] = useState("");

  const { t } = useTranslation();

  const openModal = () => {
    setOpenPurchaseModal(true);

    if (payWithCash) {
      setModalText(t("thanksForPurchase"));
    }
    if (!payWithCash) {
      setModalText(t("useCard"));
    }
  };
  console.log(openModal);

  const resetStates = () => {
    setDigitalEditionCheckbox(false);
    setPayWithCash(false);
    setOpenPurchaseModal(false);
    setModalText("");
  };

  useEffect(() => {
    if (payWithCash && openPurchaseModal) {
      const timer = setTimeout(() => {
        resetStates();
      }, 10000); // 10 seconds

      // Clean up the timer when the component unmounts or when isOpen becomes false
      return () => clearTimeout(timer);
    }
  }, [openPurchaseModal, payWithCash]);
  // Effect to close modal after 15 seconds if openPurchaseModal is true
  useEffect(() => {
    if (openPurchaseModal && !payWithCash) {
      const modalCloseTimer = setTimeout(() => {
        resetStates();
      }, 15000);

      // Clean up modalCloseTimer when openPurchaseModal becomes false
      return () => {
        clearTimeout(modalCloseTimer);
      };
    }
  }, [openPurchaseModal, payWithCash]);

  useEffect(() => {
    if (openPurchaseModal && !payWithCash) {
      const changeTextTimer = setTimeout(() => {
        setModalText(t("thanksForPurchase"));
      }, 10000);

      // Clean up modalCloseTimer when openPurchaseModal becomes false
      return () => {
        clearTimeout(changeTextTimer);
      };
    }
  }, [openPurchaseModal, payWithCash, t]);

  return (
    <>
      <li
        className="card-text-normal"
        style={{ marginTop: "20px", color: config.MUZEUM.textColor }}
      >
        {t("buyWithCash")}
        <input
          type="checkbox"
          checked={payWithCash}
          onChange={() => {
            setPayWithCash((prev) => !prev);
          }}
        />
        {payWithCash && (
          <input placeholder={t("password") || ""} type="password" />
        )}
      </li>
      <hr
        style={{
          opacity: "0.3",
        }}
      />
      {/* li */}
      <li
        className="card-text-normal"
        style={{ marginTop: "20px", color: config.MUZEUM.textColor }}
      >
        {t("getDigitalEdition")}
        <input
          type="checkbox"
          onChange={() => {
            setDigitalEditionCheckbox((prev) => !prev);
          }}
          checked={digitalEditionCheckbox}
        />
        {digitalEditionCheckbox && <input placeholder="email" />}
      </li>

      <button
        onClick={openModal}
        className="new-card-other-infos-btn"
        style={{
          color: config.MUZEUM.textColor,
          paddingBottom: "5px",
          borderBottom: `1px solid ${config.MUZEUM.textColor}`,
        }}
      >
        {t("otherInfos")}
      </button>

      {openPurchaseModal && (
        <div className="purchase-modal">
          <h1
            className="new-card-title"
            style={{
              color: config.MUZEUM.titleColor,
              fontFamily: config.MUZEUM.extraBoldFont,
            }}
          >
            {modalText}
          </h1>
        </div>
      )}
    </>
  );
};

export default Purchase;
